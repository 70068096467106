<template>
  <div>
    <!-- Report content Area -->
    <ReportLayout>
      <div class="Report_cont py-3">
        <h1>WalletReport</h1>
      </div>
    </ReportLayout>
  </div>
</template>

<script>
import ReportLayout from "@/layouts/ReportLayout.vue";

export default {
  name: "WalletReport",
  components: {
    ReportLayout,
  },
};
</script>

<style scoped></style>
