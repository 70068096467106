import { render, staticRenderFns } from "./WalletReport.vue?vue&type=template&id=069148c6&scoped=true&"
import script from "./WalletReport.vue?vue&type=script&lang=js&"
export * from "./WalletReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069148c6",
  null
  
)

export default component.exports